import styled from 'styled-components'
import rwd from 'styles/rwd'
import { Icon as BaseIcon } from '@stokr/components-library/dist/components/Icon/Icon.style'
import { EligibilityItemState } from './EligibilityModal'
import { theme } from '@stokr/components-library/dist/styles/theme'

export const Container = styled.div`
  position: relative;
`

export const Column = styled.div`
  position: relative;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${(props) =>
    props.marginTop &&
    `
  margin-top:10px;`}
`

export const Icon = styled(BaseIcon)`
  font-size: 20px;
  width: 18px;
  text-align: center;
  padding: 0 20px;

  ${(props) =>
    props.state === EligibilityItemState.DONE &&
    `
    font-size: 13px;
    color: ${theme.cSuccess};
  `}

  ${(props) =>
    props.state === EligibilityItemState.DENIED &&
    `
    color: ${theme.cWarning};
  `}

  ${(props) =>
    props.state === EligibilityItemState.NOT_DONE &&
    `
    color: #facc33; //${theme.cYellowWarning};  
  `}

  ${(props) =>
    props.state === EligibilityItemState.IN_PROGRESS &&
    `
    opacity: 0.3;
  `}

  ${rwd.Medium`
    padding: 0 30px;
  `}
`

export const Value = styled.div`
  @media (max-width: 450px) {
    min-width: 60%;
  }

  position: relative;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.4px;
  min-width: 40%;

  ${rwd.Large`

  font-size:22px;

  `}

  ${(props) =>
    props.isLink &&
    `
    text-decoration:underline;    
`}
`

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  margin-bottom: 8px;

  ${(props) =>
    props.isLink &&
    `
    cursor:pointer;    
`}
`

export const ReadyToInvestBlock = styled.div`
  padding: 10px;
  //font-size: medium;
  width: fit-content;
  border-radius: 5px;

  color: ${theme.cWhite};
  background-color: ${theme.cWarning};

  ${(props) =>
    props.isReady &&
    `
        background-color: ${theme.cSuccess}
        `}
`
