import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import TabsNav, { TabNav } from 'components/TabsNav/TabsNav'
import { Row, Column, ComponentWrapper } from '@stokr/components-library'

const SettingsLayout = ({ children, title, activeTab }) => {
  let navigate = useNavigate()
  return (
    <Layout>
      <SEO title={title} />
      <Row main>
        <Column>
          <ComponentWrapper noPadding>
            <TabsNav activeTab={activeTab}>
              {/*<TabNav tabId="account" onClick={() => {}}>Account</TabNav>*/}
              {/*<TabNav tabId="verification" onClick={() => {}}>Verification</TabNav>*/}
              {/*<TabNav tabId="security" onClick={() => {}}>Security</TabNav>*/}
              <TabNav tabId="dashboard" onClick={() => navigate('/')}>
                Back to Dashboard
              </TabNav>
              <TabNav
                tabId="profile-and-privacy"
                onClick={() => navigate('/settings/profile-and-privacy')}
              >
                Profile & Privacy
              </TabNav>
              {/*<TabNav tabId="notifications" onClick={() => {}}>Notifications</TabNav>*/}

              <TabNav
                tabId="liquid-address"
                onClick={() => navigate('/settings/liquid-address')}
              >
                Liquid Addresses
              </TabNav>
              <TabNav
                tabId="whitelisting"
                onClick={() => navigate('/settings/whitelisting')}
              >
                Whitelisting
              </TabNav>

              {/*<TabNav tabId="token-transfer" onClick={() => {}}>Token transfer</TabNav>
            <TabNav tabId="iban" onClick={() => {}}>Iban</TabNav>*/}
            </TabsNav>
          </ComponentWrapper>
          {children}
        </Column>
      </Row>
    </Layout>
  )
}

SettingsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default SettingsLayout
