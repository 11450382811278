import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Modal,
  ModalInner,
  ComponentWrapper,
  Text,
  FormInfo,
  Select,
  FormField,
  Row,
  Column,
  AuthContext,
  Button,
} from '@stokr/components-library'
import { EventDBContext } from 'context/EventDBContext/EventDBContext'
import { ProjectStatus } from 'constants/enums'
import { iconsMap } from '@stokr/components-library/dist/components/Icon/Icon.style'
import { isUSInvestor } from '@stokr/components-library/dist/utils/isUSInvestor'
import {
  Icon,
  ReadyToInvestBlock,
  Value,
  ValueContainer,
} from './Eligibility.styles'
import fetchData from 'api/fetch-data'
import Auth from '@stokr/components-library/dist/context/Auth'
const availableMarkets = [
  {
    name: 'primary-market',
    label: 'Primary market (invest)',
  },
  {
    name: 'secondary-market',
    label: 'Secondary market (trade)',
  },
]

const websiteUrl = process.env.REACT_APP_WEBSITE_DOMAIN

export const EligibilityItemState = {
  DONE: 'done',
  NOT_DONE: 'not_done',
  NOT_ALLOWED: 'not_allowed',
  WARNING: 'warning',
}

export const EligibilityStatusIcons = {
  [EligibilityItemState.DONE]: iconsMap.check,
  [EligibilityItemState.NOT_DONE]: iconsMap.warning,
  [EligibilityItemState.NOT_ALLOWED]: iconsMap.cross,
  [EligibilityItemState.WARNING]: iconsMap.warning,
}

const useEligibility = (project, selectedMarket, user) => {
  const [eligibilityItems, setEligibilityItems] = useState([])
  const [isReadyToInvest, setisReadyToInvest] = useState(false)

  const checkEligibility = useCallback(() => {
    if (!user || !project || !selectedMarket) return

    const items = []

    const hasWallet = user.wallets?.length > 0
    const isRiskQsDone = user.is_risk_questionnaire_finished
    const isKYCAccepted = user.kyc_status?.toUpperCase() === 'ACCEPTED'
    const isPrivateInvestorForSelectedProject =
      user.privateInvestorProjects?.find(
        (x) => x.projectId === project._id,
      )?.isAllowed

    const isFromAllowedCountry =
      user?.countryObj &&
      (!isUSInvestor(user) || project.is_us_allowed) &&
      (isPrivateInvestorForSelectedProject
        ? user.countryObj.isAllowed
        : user.countryObj.allPublicSales || user.countryObj[project.name])

    const isPrimaryMarket = selectedMarket.name === 'primary-market'
    const isSecondaryMarket = selectedMarket.name === 'secondary-market'

    // Basic eligibility checks based on user
    //these steps are always required
    items.push(
      {
        name: 'KYC / AML',
        status: isKYCAccepted
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://signup.${websiteUrl}/verify-identity`,
        firstRow: true,
        showLink: !isKYCAccepted,
      },
      {
        name: 'Wallet',
        status: hasWallet
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://signup.${websiteUrl}/register-liquid-securities`,
        firstRow: true,
        showLink: !hasWallet,
      },
      {
        name: 'Risk Questionnaire',
        status: isRiskQsDone
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://signup.${websiteUrl}/risk-questionnaire`,
        firstRow: true,
        showLink: !isRiskQsDone,
      },
    )

    // Tax ID check
    if (
      (project.requiresTaxId && isPrimaryMarket) ||
      (project.whitelistOnlyWithTaxId && isSecondaryMarket)
    ) {
      const hasTaxId = !!user.taxId
      items.push({
        name: 'Tax ID',
        status: hasTaxId
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://signup.${websiteUrl}/taxid`,
        showLink: !hasTaxId,
      })
    }

    //Private investor check
    if (
      (project.only_private_investors && isPrimaryMarket) ||
      (project.whitelistOnlyPrivateInvestors && isSecondaryMarket)
    ) {
      items.push({
        name: 'Private Investor',
        status: isPrivateInvestorForSelectedProject
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://${websiteUrl}/featured-assets/${project.name}`,
        showLink: !isPrivateInvestorForSelectedProject,
      })
    }

    //Allowed country check
    items.push({
      name: 'Allowed country',
      status: isFromAllowedCountry
        ? EligibilityItemState.DONE
        : EligibilityItemState.NOT_DONE,
      link: `https://signup.${websiteUrl}/country-of-residence`,
      showLink: !isFromAllowedCountry,
    })

    //case for BMN2
    //     condition for project.name === 'blockstream-mining2'

    // !user.countryObj.eu || user.professional_investor_status === "professional" ||  investments.find(investment => investment.project === bmn2ProjectId && investment.fulfilled)
    checkIsReadyToInvest(items)
    setEligibilityItems(items)
  }, [user, project, selectedMarket])

  //check all eligilibility items and their status to find if user can invest
  const checkIsReadyToInvest = (items) => {
    const hasOneNotDoneStatus = items.find(
      (item) => item.status === EligibilityItemState.NOT_DONE,
    )

    if (!hasOneNotDoneStatus && !isReadyToInvest) {
      setisReadyToInvest(true)
    } else {
      setisReadyToInvest(false)
    }
  }

  useEffect(() => {
    checkEligibility()
  }, [checkEligibility, project, selectedMarket, user])

  return {
    eligibilityItems,
    isReadyToInvest,
  }
}

function EligibilityModal(props) {
  const { isModalOpen, onModalClose } = props
  const { projects } = useContext(EventDBContext)
  const { user, setUser } = useContext(AuthContext)

  const [selectedProject, setselectedProject] = useState()
  const [selectedMarket, setselectedMarket] = useState(availableMarkets[0])

  const { eligibilityItems, isReadyToInvest } = useEligibility(
    selectedProject,
    selectedMarket,
    user,
  )

  useEffect(() => {
    if (!user.privateInvestorProjects) fetchUserProjects()
  }, [])

  const fetchUserProjects = async () => {
    try {
      const { list } = await Auth.checkPrivateInvestor()
      if (list) {
        setUser({ ...user, privateInvestorProjects: list })
      }
    } catch (error) {
      console.error('🚀 ~ fetchUserProjects ~ error:', error)
    }
  }

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      <ModalInner noPadding>
        <ComponentWrapper noPaddingBottom>
          <Text>
            <h3>Check your eligibility for a specific investment</h3>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper>
          {/* Select project or token */}
          <ComponentWrapper noPaddingHorizontal noPaddingBottom>
            <FormField>
              <Select
                id="project"
                name="project"
                value={selectedProject}
                options={projects
                  ?.filter((project) => project.status === ProjectStatus.ACTIVE)
                  .map((project) => {
                    return {
                      value: project,
                      label: project.name,
                      key: project.name,
                    }
                  })}
                label="Choose the project:"
                onChange={(data) => {
                  setselectedProject(data.value)
                }}
              />
            </FormField>
          </ComponentWrapper>

          {/* select market */}
          <ComponentWrapper noPaddingHorizontal>
            <FormField>
              <Select
                id="market"
                name="market"
                value={selectedMarket}
                options={availableMarkets.map((market) => {
                  return {
                    value: market,
                    label: market.label,
                    key: market.name,
                  }
                })}
                label="Select market"
                disabled={!selectedProject}
                onChange={(data) => {
                  setselectedMarket(data.value)
                }}
              />
              {!selectedProject && (
                <FormInfo>The Project must be selected first</FormInfo>
              )}
            </FormField>
          </ComponentWrapper>

          {!selectedProject || eligibilityItems.length === 0 ? (
            <ComponentWrapper noPaddingHorizontal>
              <Text>
                <p>
                  Choose a project and market to check your eligibility status.{' '}
                </p>
              </Text>
            </ComponentWrapper>
          ) : (
            <>
              {/* ELIGIBILITY ITEMS */}
              <ComponentWrapper noPaddingHorizontal>
                <Row flex spaceBetween>
                  <>
                    <Column halfWidth>
                      {eligibilityItems
                        .filter((item) => item.firstRow)
                        .map((item) => {
                          return renderEligibilityItem(item)
                        })}
                    </Column>
                    <Column halfWidth>
                      {eligibilityItems
                        .filter((item) => !item.firstRow)
                        .map((item) => {
                          return renderEligibilityItem(item)
                        })}
                    </Column>
                  </>
                </Row>
              </ComponentWrapper>

              {/* IS READY TO INVEST BLOCK */}
              <ComponentWrapper
                noPaddingHorizontal
                flex
                style={{ justifyContent: 'space-between' }}
              >
                <ReadyToInvestBlock isReady={isReadyToInvest}>
                  {isReadyToInvest ? 'Ready to invest' : 'Not ready to invest'}
                </ReadyToInvestBlock>
                {isReadyToInvest && (
                  <Button
                    onClick={() => {
                      window.open(
                        `https://${websiteUrl}/${selectedProject.name}`,
                      )
                    }}
                  >
                    More details
                  </Button>
                )}
              </ComponentWrapper>
            </>
          )}
        </ComponentWrapper>
      </ModalInner>
    </Modal>
  )
}

const renderEligibilityItem = (item) => (
  <ValueContainer isLink={item.showLink}>
    <Value isLink={item.showLink}>
      {item.showLink ? (
        <a href={item.link} alt={item.name} style={{ fontWeight: 'inherit' }}>
          {item.name}
        </a>
      ) : (
        item.name
      )}
    </Value>
    <Icon icon={EligibilityStatusIcons[item.status]} state={item.status} />
  </ValueContainer>
)

export default EligibilityModal
