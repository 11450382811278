import React from 'react'
import PropTypes from 'prop-types'

import {
  ComponentWrapper,
  Row,
  Column,
  Text,
  Modal,
  ModalInner,
  ToDoList,
  ToDoTask,
} from '@stokr/components-library'
import {
  Title,
  TitleContainer,
} from '@stokr/components-library/dist/components/ToDoList/ToDoList.styles'

const WhitelistingStepsModal = ({
  isModalOpen,
  onModalClose,
  title,
  description,
  projectName,
  whitelistingSteps,
}) => {
  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      <Row>
        <Column part={8}>
          <ModalInner modalTop>
            <Text>
              <h3>{title}</h3>
              <p>
                {description}
                <span style={{ textTransform: 'capitalize' }}>
                  {projectName}
                </span>
              </p>
            </Text>
          </ModalInner>
          <ModalInner modalBot>
            {/* <Text style={{ fontSize: '12px' }}>
              Complete these steps to be eligible for investment in this
              project.
            </Text> */}
          </ModalInner>
        </Column>

        <Column part={8}>
          <ModalInner>
            <div>
              <ComponentWrapper
                noPaddingHorizontal
                style={{ padding: '8px 0 16px' }}
              >
                <TitleContainer>
                  <Title>Required Steps</Title>
                </TitleContainer>
              </ComponentWrapper>
              <ComponentWrapper noPaddingHorizontal style={{ padding: '16px' }}>
                {whitelistingSteps.map((step) => (
                  <ToDoTask
                    key={step.id}
                    title={step.title}
                    message={step.description}
                    link={step.link}
                    state={step.status}
                    icon={step.icon}
                  />
                ))}
              </ComponentWrapper>
            </div>
          </ModalInner>
        </Column>
      </Row>
    </Modal>
  )
}

WhitelistingStepsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  whitelistingSteps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link: PropTypes.string,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default WhitelistingStepsModal
