import React, { useCallback, useContext, useEffect, useState } from 'react'
import SettingsLayout from 'components/SettingsLayout/SettingsLayout'
import {
  Button,
  Row,
  Column,
  ComponentWrapper,
  SectionTitle,
  Text,
  AuthContext,
  InputWithButton,
} from '@stokr/components-library'
import { EventDBContext } from 'context/EventDBContext/EventDBContext'
import { PrivateInvestorStatuses, ProjectStatus } from 'constants/enums'

import Auth from '@stokr/components-library/dist/context/Auth'
import styled from 'styled-components'
import { ReactTableWrapper } from '@stokr/components-library/dist/components/AdminDashboard/Table/ReactTable'
import {
  Icon,
  ReadyToInvestBlock,
} from 'components/Modal/EligibilityModal/Eligibility.styles'
import { isUSInvestor } from '@stokr/components-library/dist/utils/isUSInvestor'
import WhitelistingStepsModal from 'components/Modal/ChecklistModal/ChecklistStepsModal'
import TabNav from 'components/TabsNav/TabNav'
import TabsNav from 'components/TabsNav/TabsNav'
import { colors } from '@stokr/components-library/dist/styles/colors'
import {
  iconsMap,
  TooltipIcon,
} from '@stokr/components-library/dist/components/Icon/Icon.style'
import { Tooltip } from 'react-tippy'
import newTabIcon from 'static/images/new_tab_icon.png'
import { loaderGif } from '@stokr/components-library/dist/components/StokrLoader/StokrLoader'
import { getVerifyIdentityChecklist } from '@stokr/components-library/dist/components/Checklist/UserChecklist'

const mainColumns = [
  {
    label: 'Asset',
    key: 'asset',
    accessor: 'asset',
    Header: 'Asset',
  },
  {
    label: 'Status',
    key: 'status',
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }) => value,
  },
  {
    label: 'Next steps',
    key: 'action',
    accessor: 'action',
    Header: 'Next steps',
    Cell: ({ value }) => value,
  },
]

export const EligibilityItemState = {
  DONE: 'done',
  NOT_DONE: 'not_done',
  NOT_ALLOWED: 'not_allowed',
  IN_PROGRESS: 'in_progress',
}

export const EligibilityStatusIcons = {
  [EligibilityItemState.DONE]: iconsMap.check,
  [EligibilityItemState.NOT_DONE]: iconsMap.warning,
  [EligibilityItemState.NOT_ALLOWED]: iconsMap.cross,
  [EligibilityItemState.IN_PROGRESS]: iconsMap.inProgress,
}

const websiteUrl = process.env.REACT_APP_WEBSITE_DOMAIN

const MARKET_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
}

const getProjectEligibility = (user, project, market, investments = []) => {
  if (!user || !project)
    return { items: [], isEligible: false, isNotAllowed: false }

  const isPrimaryMarket = market === MARKET_TYPES.PRIMARY
  const isSecondaryMarket = market === MARKET_TYPES.SECONDARY

  const {
    isEURestricted,
    _id,
    only_private_investors,
    privateSaleOpen,
    whitelistOnlyPrivateInvestors,
    requiresTaxId,
    whitelistOnlyWithTaxId,
  } = project

  const items = []
  const hasWallet = user.wallets?.length > 0
  const isKYCAccepted = user.kyc_status?.toUpperCase() === 'ACCEPTED'

  const privateInvestorObj = user.privateInvestorProjects?.find(
    (x) => x.projectId === _id,
  )

  const isFromAllowedCountry = checkIsFromAllowedCountryForProject(
    user,
    project,
    privateInvestorObj?.isAllowed,
  )

  items.push({
    name: 'Allowed country',
    status: isFromAllowedCountry
      ? EligibilityItemState.DONE
      : EligibilityItemState.NOT_ALLOWED,
    errorMessage: 'Country not allowed',
    detailsMessage:
      'We noted that you live in a country where the offering is not yet available',
  })

  if (isFromAllowedCountry) {
    items.push(
      {
        name: 'KYC / AML',
        key: 'kyc',
        status: isKYCAccepted
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://signup.${websiteUrl}/verify-identity`,
        firstRow: true,
        showLink: !isKYCAccepted,
        errorMessage: 'KYC / AML not done',
        CTAText: 'Verify now',
        description: getVerifyIdentityChecklist(isFromAllowedCountry, user)
          .message,
      },
      {
        name: 'Wallet',
        key: 'wallet',
        status: hasWallet
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://signup.${websiteUrl}/register-liquid-securities`,
        firstRow: true,
        showLink: !hasWallet,
        errorMessage: 'Wallet not registered',
        description:
          'Register a Liquid address where you will receive the securities',
        CTAText: 'Register wallet',
      },
    )

    if (
      (requiresTaxId && isPrimaryMarket) ||
      (whitelistOnlyWithTaxId && isSecondaryMarket)
    ) {
      const hasTaxId = !!user.taxId
      items.push({
        name: 'Tax ID',
        key: 'taxId',
        status: hasTaxId
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://signup.${websiteUrl}/taxid`,
        showLink: !hasTaxId,
        errorMessage: 'Tax ID not provided',
        description: 'Provide your tax ID to be eligible',
        CTAText: 'Add now',
      })
    }

    if (
      (only_private_investors && isPrimaryMarket) ||
      (whitelistOnlyPrivateInvestors && isSecondaryMarket)
    ) {
      items.push({
        name: 'Private Investor',
        key: 'private-investor',
        status: !privateInvestorObj
          ? EligibilityItemState.NOT_DONE
          : privateInvestorObj.status !== PrivateInvestorStatuses.ADMITTED
          ? EligibilityItemState.IN_PROGRESS
          : EligibilityItemState.DONE,
        link: `https://${websiteUrl}/featured-assets/${project.name}`,
        showLink: !!privateInvestorObj,
        description: !privateInvestorObj
          ? 'Apply for private investor status to be eligible'
          : privateInvestorObj.status !== PrivateInvestorStatuses.ADMITTED
          ? 'Your profile is under review. An account managerl will reach out to you to confirm your eligibility. '
          : '',
        errorMessage: !privateInvestorObj
          ? 'Not applied for eligibility'
          : privateInvestorObj.status !== PrivateInvestorStatuses.ADMITTED
          ? 'Application still pending'
          : '',
        CTAText: !privateInvestorObj
          ? 'Register now'
          : privateInvestorObj.status !== PrivateInvestorStatuses.ADMITTED
          ? 'Check status'
          : '',
      })
    }

    if (isEURestricted && isSecondaryMarket && user.countryObj.eu) {
      const hasInvestedOnPrimaryMarket = investments.some(
        (investment) => investment.project === _id && investment.fulfilled,
      )
      items.push({
        name: 'Professional Investor',
        key: 'professional-investor',
        status: hasInvestedOnPrimaryMarket
          ? EligibilityItemState.DONE
          : EligibilityItemState.NOT_DONE,
        link: `https://${websiteUrl}/featured-assets/${project.name}`,
        showLink: !hasInvestedOnPrimaryMarket,
        description: !hasInvestedOnPrimaryMarket
          ? 'You have to apply as a professional investor to invest'
          : '',
        errorMessage: 'Only available to professional investors',
        CTAText: 'Apply now',
      })
    }
  }

  const isNotAllowed = items.some(
    (item) => item.status === EligibilityItemState.NOT_ALLOWED,
  )
  const isEligible = items.every(
    (item) => item.status === EligibilityItemState.DONE,
  )

  return {
    items,
    isEligible,
    isNotAllowed,
  }
}

const WhitelistingPage = () => {
  const { user, setUser } = useContext(AuthContext)
  console.log('🚀 ~ WhitelistingPage ~ user:', user)
  const { projects, finalizedTx, isLoadingData } = useContext(EventDBContext)
  const [whitelistingData, setWhitelistingData] = useState([])
  console.log('🚀 ~ WhitelistingPage ~ whitelistingData:', whitelistingData)
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedMarket, setSelectedMarket] = useState(MARKET_TYPES.PRIMARY)

  useEffect(() => {
    if (!user.privateInvestorProjects) fetchUserProjects()
  }, [])

  const fetchUserProjects = async () => {
    try {
      const { list } = await Auth.checkPrivateInvestor()
      if (list) {
        setUser({ ...user, privateInvestorProjects: list })
      }
    } catch (error) {
      console.error('🚀 ~ fetchUserProjects ~ error:', error)
    }
  }

  useEffect(() => {
    if (projects && user) {
      console.log('🚀 ~ useEffect ~ projects:', projects)
      const activeProjects = projects.filter(
        (project) =>
          project.status === ProjectStatus.ACTIVE && !project.is_static,
      )

      const projectsData = activeProjects.map((project) => {
        const { items, isEligible, isNotAllowed } = getProjectEligibility(
          user,
          project,
          selectedMarket,
          finalizedTx,
        )

        const showLoader =
          selectedMarket === MARKET_TYPES.SECONDARY &&
          project.isEURestricted &&
          isLoadingData

        return {
          asset: renderProject(project),
          status: showLoader
            ? tableDataLoader()
            : renderStatus(isEligible, isNotAllowed, items),
          action: showLoader
            ? tableDataLoader()
            : renderAction(
                isEligible,
                isNotAllowed,
                items,
                project,
                selectedMarket,
                setSelectedProject,
                setIsModalOpen,
              ),
          project,
        }
      })

      setWhitelistingData(projectsData)
      setFilteredData(projectsData)
    }
  }, [projects, user, selectedMarket, finalizedTx])

  const handleSearch = (value) => {
    setSearchTerm(value)
    const lowercaseSearch = value.toLowerCase()
    const filtered = whitelistingData.filter(
      (item) =>
        item.project.name.toLowerCase().includes(lowercaseSearch) ||
        item.project.tokenSymbol.toLowerCase().includes(lowercaseSearch),
    )
    setFilteredData(filtered)
  }

  const getWhitelistingSteps = (items) => {
    return items
      .filter((item) => item.status !== EligibilityItemState.DONE)
      .map((item, index) => ({
        id: String(index + 1),
        title: item.name,
        description: item.description,
        link: item.link,
        status: item.status,
        icon: EligibilityStatusIcons[item.status],
      }))
  }

  const marketTabs = [
    { id: MARKET_TYPES.PRIMARY, label: 'Primary Market' },
    { id: MARKET_TYPES.SECONDARY, label: 'Secondary Market' },
  ]

  return (
    <SettingsLayout title="Settings" activeTab="whitelisting">
      <ComponentWrapper>
        <Row>
          <Column>
            <ComponentWrapper noPaddingHorizontal>
              <SectionTitle>Settings</SectionTitle>
            </ComponentWrapper>
            <ComponentWrapper noPadding>
              <Text>
                <h3>Whitelisting of assets</h3>
              </Text>
            </ComponentWrapper>
          </Column>
        </Row>

        <Row>
          <Column>
            <ComponentWrapper noPaddingHorizontal>
              <Text>
                <p>
                  Check your whitelisting status for different assets. Complete
                  all requirements to become eligible for investment.
                </p>
              </Text>
            </ComponentWrapper>
          </Column>
        </Row>

        {projects.length === 0 ? (
          <ComponentWrapper center>
            <Text blockchainLoadingMsg>
              <h4>A few seconds left. We are still loading the data...</h4>
            </Text>
            <img
              src={loaderGif}
              style={{ height: 200, marginTop: 32 }}
              alt="&nbsp;"
            />
          </ComponentWrapper>
        ) : (
          <>
            <Row>
              <Column>
                <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                  <InputWithButton
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search for assets..."
                    type="text"
                    icon="search"
                  />
                </ComponentWrapper>
              </Column>
            </Row>

            <Row>
              <Column>
                <TabsNav activeTab={selectedMarket}>
                  {marketTabs.map((tab) => (
                    <TabNav
                      tabId={tab.id}
                      key={tab.id}
                      onClick={() => setSelectedMarket(tab.id)}
                    >
                      {tab.label}
                    </TabNav>
                  ))}
                </TabsNav>
              </Column>
            </Row>

            <Row>
              <Column>
                <ComponentWrapper noPaddingHorizontal noPaddingTop>
                  <ReactTableWrapper
                    columns={mainColumns}
                    data={filteredData}
                    customTdStyles={customTdStyles}
                    customThStyles={customThStyles}
                  />
                </ComponentWrapper>
              </Column>
            </Row>
          </>
        )}
        {selectedProject && (
          <WhitelistingStepsModal
            isModalOpen={isModalOpen}
            onModalClose={() => {
              setIsModalOpen(false)
              setSelectedProject(null)
            }}
            title="Whitelisting Requirements"
            description="Complete the following steps to be whitelisted for "
            projectName={selectedProject.name}
            whitelistingSteps={getWhitelistingSteps(selectedProject.items)}
          />
        )}
      </ComponentWrapper>
    </SettingsLayout>
  )
}

const checkIsFromAllowedCountryForProject = (
  user,
  project,
  isPrivateInvestor,
) => {
  if (!user?.countryObj) return false

  const isUSRestricted = isUSInvestor(user) && !project.is_us_allowed
  if (isUSRestricted) return false

  const { only_private_investors, privateSaleOpen } = project

  const isPublicSaleAllowed =
    user.countryObj.allPublicSales || user.countryObj[project.name]
  const isPrivateSaleAllowed = privateSaleOpen && isPrivateInvestor

  return only_private_investors
    ? user.countryObj.isAllowed || isPrivateSaleAllowed
    : isPublicSaleAllowed || isPrivateSaleAllowed
}

const customThStyles = (column) => {
  return { backgroundColor: colors.lightGrey, fontWeight: 700 } // default style
}

const customTdStyles = (index, row) => {
  return {
    // borderRight: `1px solid ${colors.lightGrey}`,
    borderBottom: `2px solid ${colors.lightGrey}`,
    // borderCollapse: 'collapse',
    padding: 20,
    minHeight: 70,
    height: 90,
  } // default style
}

const renderStatus = (isEligible, isNotAllowed, items) => {
  if (isEligible) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon
          icon={EligibilityStatusIcons[EligibilityItemState.DONE]}
          state={EligibilityItemState.DONE}
          style={{ padding: 0, marginRight: '15px', fontSize: 20 }}
        />

        <Text style={{ fontWeight: 600, fontSize: 16 }}>
          Eligible for investment
        </Text>
      </div>
    )
  }

  if (isNotAllowed) {
    const notAllowedItem = items.find(
      (item) => item.status === EligibilityItemState.NOT_ALLOWED,
    )
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          icon={EligibilityStatusIcons[EligibilityItemState.NOT_ALLOWED]}
          state={EligibilityItemState.NOT_ALLOWED}
          style={{ padding: 0, marginRight: '15px', fontSize: 18 }}
        />
        <Text style={{ fontWeight: 500, fontSize: 16 }}>
          {notAllowedItem.errorMessage}
        </Text>
      </div>
    )
  }

  const missingItems = items.filter(
    (item) =>
      item.status === EligibilityItemState.NOT_DONE ||
      item.status === EligibilityItemState.IN_PROGRESS,
  )
  if (missingItems.length > 1) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          icon={EligibilityStatusIcons[EligibilityItemState.NOT_DONE]}
          state={EligibilityItemState.NOT_DONE}
          style={{ padding: 0, marginRight: '15px', fontSize: 20 }}
        />
        <Text style={{ fontSize: 16 }}>
          {missingItems.length} steps missing to complete
        </Text>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        icon={EligibilityStatusIcons[EligibilityItemState.NOT_DONE]}
        state={EligibilityItemState.NOT_DONE}
        style={{ padding: 0, marginRight: '15px', fontSize: 20 }}
      />
      <Text style={{ fontWeight: 500, fontSize: 16 }}>
        {missingItems.map((item) => item.errorMessage)}
      </Text>
    </div>
  )
}

const renderProject = (project) => {
  return (
    <div
      style={{
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      }}
      onClick={() => {
        const url = `https://${websiteUrl}/${project.name}`
        window.open(url, '_blank')
      }}
    >
      {project.tokenSymbol} - {project.tokenName}
      <img src={newTabIcon} alt="new tab" style={{ width: 15, height: 15 }} />
    </div>
  )
}

const tableDataLoader = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
      <img src={loaderGif} alt="loader" style={{ width: 25, height: 25 }} />
      Loading data...
    </div>
  )
}

const renderAction = (
  isEligible,
  isNotAllowed,
  items,
  project,
  selectedMarket,
  setSelectedProject,
  setIsModalOpen,
) => {
  if (isEligible) {
    return (
      <Button
        minWidth="200px"
        onClick={() => {
          const url =
            selectedMarket === MARKET_TYPES.PRIMARY
              ? `https://${websiteUrl}/${project.name}`
              : `https://sideswap.io/swap-market/?product=${project.tokenSymbol}`
          window.open(
            url,
            selectedMarket === MARKET_TYPES.SECONDARY ? '_blank' : '_self',
          )
        }}
      >
        {selectedMarket === MARKET_TYPES.PRIMARY
          ? 'Invest'
          : 'Trade on SideSwap'}
      </Button>
    )
  }

  if (isNotAllowed) {
    const notAllowedItem = items.find(
      (item) => item.status === EligibilityItemState.NOT_ALLOWED,
    )
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ fontSize: 'inherit' }}>
          Not available in your country
        </Text>
        <Tooltip
          position="top"
          title={notAllowedItem.detailsMessage}
          theme="light"
          arrow
          duration={200}
        >
          <TooltipIcon onClick={(e) => e.preventDefault()} />
        </Tooltip>
      </div>
    )
  }

  const notDoneItems = items.filter(
    (item) =>
      item.status === EligibilityItemState.NOT_DONE ||
      item.status === EligibilityItemState.IN_PROGRESS,
  )

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        setSelectedProject({
          name: project.name,
          items: notDoneItems,
        })
        setIsModalOpen(true)
      }}
      minWidth="200px"
      secondary
    >
      {notDoneItems.length === 1 ? notDoneItems[0].CTAText : 'Complete All'}
    </Button>
  )
}

export default WhitelistingPage
